export default () => {
  return (
    <div className="contact">
      <div className="contact-bgline"></div>
      <div className="rectangle content flex-row">
        <div className="rectangle-wrapper">
          <div className="rectangle-text">
            <i className="fa fa-map-marker" />
            <h3>Kde nás nájdete?</h3>
            <p>Veterinárna klinika Poprad</p>
            <p>Hraničná 666/12</p>
            <p>058 01 Poprad</p>
          </div>
        </div>
        <div className="rectangle-wrapper">
          <div className="rectangle-text">
            <i className="fa fa-clock-o" />
            <h3>Ordinačné hodiny</h3>
            <p>Pon-Pia</p>
            <p>8:00-18:00</p>
            <p>Sob-Ned</p>
            <p>9:00-12:00</p>
          </div>
        </div>
        <div className="rectangle-wrapper">
          <div className="rectangle-text">
            <i className="fa fa-commenting-o" />
            <h3>Spojte sa s nami</h3>
            <p>0911 761 664</p>
            <p>info@veterinapp.sk</p>
            <p>www.veterinapoprad.sk</p>
          </div>
        </div>
      </div>
    </div>
  );
};
