import React, { useState } from 'react';

import { set, get } from '../utils/storage';
import { keyCookieChecked } from '../enums';

export default () => {
  const [hidden, setHidden] = useState(get(keyCookieChecked) === 'true');

  // set(keyCookieChecked, false);

  const handleSubmit = agreed => {
    set(keyCookieChecked, true);
    setHidden(true);
  };

  if (hidden) {
    return <div />;
  }

  return (
    <div className="cookies-bar">
      <div className="content flex-row">
        <p className="cookies-bar-text">
          Táto stránka používa len technické cookies. Informácie o využívaní cookies nájdete v dokumente{' '}
          <a href="https://veterinapoprad.sk//nahalkavet_cookies.pdf" target="_blank">
            Pravidlá cookies
          </a>
          {' a '}
          <a href="https://veterinapoprad.sk//nahalkavet_gdpr.pdf" target="_blank">
            Ochrana osobných údajov
          </a>
        </p>
        <div className="cookies-bar-button" onClick={() => handleSubmit(true)}>
          Súhlasím
        </div>
        <div className="cookies-bar-button negative" onClick={() => handleSubmit(false)}>
          Nesúhlasím
        </div>
      </div>
    </div>
  );
};
