export default () => {
  return (
    <div className="header-banner">
      <div className="header-overlay">
        <div className="content flex-col">
          <h1>
            Pre nás to nie sú iba
            <br />
            zvieratá, ale vaša rodina
          </h1>
          <div className="button">
            <a href="tel:+421911761664">POHOTOVOSŤ</a>
          </div>
        </div>
      </div>
    </div>
  );
};
