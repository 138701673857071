import React, { useState } from 'react';
import classNames from 'classnames';

const questions = [
  {
    id: 1,
    question: 'Kedy je vaša pohotovosť otvorená?',
    answer: 'Pohotovosť je tu pre vás 24 hodín denne, 7 dní v týždni, každý deň v roku.',
  },
  {
    id: 2,
    question: 'Aké stavy ošetrujeme na pohotovosti?',
    answer:
      'Akútne, neodkladné, život a zdravie ohrozujúce stavy. Najčastejšie sú to úrazy, napadnutie inými zvieratami a hmyzom, otrava, torzie žalúdka, komplikované pôrody, ale aj náhle zhoršenia zdravotného stavu, napr. prudké vracania, krvavé hnačky, silná dýchavičnosť a pod.',
  },
  {
    id: 3,
    question: 'Čo signalizuje akútne stavy?',
    answer:
      'Dehydratácia (zviera viac ako 24 hodín neprijíma tekutiny), veľké hnačky a zvracanie, nafúknuté a tvrdé brucho, záchvaty - svalové kŕče, zviera sa nedokáže postaviť, sťažené dýchanie, bezvedomie.',
  },
  {
    id: 4,
    question: 'Je potrebné zavolať vopred?',
    answer:
      'Áno, keďže službukonajúci veterinárny doktor nemusí byť práve na klinike. Taktiež je možné, že problém bude možné vyriešiť už po telefóne.',
  },
  {
    id: 5,
    question: 'Pes má horúčku, čo mám robiť?',
    answer:
      'Ak máte pocit, že vaše zviera má horúčku, vždy si to overte zmeraním teploty digitálnym teplomerom v konečníku. Normálna teplota je do 39°C. V žiadnom prípade mu nedávajte ľudské tabletky!',
  },
  {
    id: 6,
    question: 'Našiel som stratené zviera, čo mám robiť?',
    answer:
      'Zavolajte na našu pohotovosť a po dohode ho prineste ku nám. My vám pomôžeme vrátiť zviera pôvodnému majiteľovi, alebo budeme kontaktovať príslušné úrady.',
  },
];

export default () => {
  const [openedQuestion, setOpenedQuestion] = useState(null);

  return (
    <div className="faq content flex-col">
      <h1>Často kladené otázky</h1>
      <div className="faq-questions">
        {questions.map(q => (
          <div
            key={q.id}
            className="question"
            onClick={() => setOpenedQuestion(openedQuestion === q.id ? null : q.id)}
          >
            <div className="question-text">
              {openedQuestion === q.id ? <i className="fa fa-minus"></i> : <i className="fa fa-plus"></i>}
              {q.question}
            </div>
            <div className={classNames('question-answer', { opened: openedQuestion === q.id })}>
              <p>{q.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
