export default () => {
  return (
    <div className="footer">
      <div className="content">
        <div className="flex-row">
          <div className="flex-col">
            <a href="https://veterinapoprad.sk/nahalkavet_cookies.pdf" target="_blank">
              Pravidlá cookies
            </a>
            <a href="https://veterinapoprad.sk/nahalkavet_gdpr.pdf" target="_blank">
              Ochrana osobných údajov
            </a>
          </div>
          <div className="flex-col">
            <p>
              <a href="https://www.facebook.com/veterinapoprad" target="_blank">
                <i className="fa fa-facebook-square" />
              </a>
              <a href="https://www.instagram.com/klinikapoprad" target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </p>
          </div>
        </div>
        <div className="copyright flex-row">
          <p>© 2024 coded with ReactJS</p>
        </div>
      </div>
    </div>
  );
};
