import React from 'react';

import HeaderBanner from './components/HeaderBanner';
import HeaderBar from './components/HeaderBar';
import Contact from './components/Contact';
import FAQ from './components/FAQ';
import EmergencyProcess from './components/EmergencyProcess';
import Footer from './components/Footer';
import CookiesBar from './components/CookiesBar';

export default () => {
  return (
    <div>
      <HeaderBar />
      <HeaderBanner />
      <Contact />
      <EmergencyProcess />
      <FAQ />
      <Footer />
      <CookiesBar />
    </div>
  );
};
