export default () => {
  return (
    <div className="header-bar">
      <div className="content flex-row">
        <div className="left">
          <p>24 HODÍN DENNE 7 DNÍ V TÝŽDNI</p>
        </div>
        <div className="right flex-row">
          <a href="tel:+421911761664">
            <i className="fa fa-phone" />
            0911 761 664
          </a>
          <a href="mailto:info@veterinapoprad.sk">
            <i className="fa fa-envelope" />
            info@veterinapoprad.sk
          </a>
        </div>
      </div>
    </div>
  );
};
