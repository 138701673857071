export default () => {
  return (
    <div className="emergency content flex-col">
      <h1>Čo robiť v prípade núdze?</h1>
      <div className="emergency-process">
        <div className="step next">
          <h2>1. krok</h2>
          <h3>Kedy zamieriť na pohotovosť?</h3>
          <p>
            Veterinárna pohotovosť slúži na riešenie akútnych a neodkladných situácií. Zranenia a stavy, ktoré vyžadujú
            neodkladnú veterinárnu starostlivosť.
          </p>
        </div>
        <div className="step next">
          <h2>2. krok</h2>
          <h3>Vždy volajte vopred</h3>
          <p>
            V akútnom stave zatelefonujte na telefónne číslo 0911 761 664 a službukonajúcemu veterinárnemu lekárovi
            vysvetlíte problém a dohodnite si ďalší postup.
          </p>
        </div>
        <div className="step">
          <h2>3. krok</h2>
          <h3>Ak problém nie je riešiteľný telefonicky</h3>
          <p>
            Službukonajúci veterinárny lekár s vami dohodne vyšetrenie na klinike. Myslite na to, že lekár nemusí byť
            vždy fyzicky prítomný na klinike. Preto nás vždy najprv kontaktujte telefonicky.
          </p>
        </div>
      </div>
    </div>
  );
};
